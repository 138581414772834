
import DomainPic from '../../../assets/revampHomeassets/DomainPic.svg';
import FinderImg from '../../../assets/revampHomeassets/finderImg.svg';
import VerificationImg from '../../../assets/revampHomeassets/verifierImg.svg';
import email from 'assets/gmailIcon.png';

export const heroImgData = [
    {
        id: 1,
        pic: FinderImg,
    },
    {
        id: 1,
        pic: VerificationImg,
    },
    {
        id: 1,
        pic: DomainPic,
    },
]

export const featuresData =[
    {
        heading:'Email Verifier',
        caption:"Market-leading & user-friendly email verification with exceptional customer support.",
        path: "/email-verifier",
        icon: email
    },
    {
        heading:"Domain Verifier",
        caption:"Verify the Domain of company of your likings With Confidence.",
        path: "/domain-verifier",
        icon: email
    },
    {
        heading:"Email from name & Domain",
        caption:"Trying to find out a contact within a corporation??, then you are at the right Place.",
        path: "/email-from-name-and-domain",
        icon: email
    },
    {
        heading:"Search Company",
        caption:"A Powerful tool that provides valuable insights about companies of interest.",
        icon: email,
        path: "/search-company",
    },
    {
        heading:"Bulk tasks",
        caption:"For huge lists of emails to find or verify, Bulk verifier and finder are fast and efficient way to aid you.",
        icon: email,
        path: "/bulks",
    },
    {
        heading:"Campaigns",
        caption:"Run camppaigns with Discovemail's customised plans.",
        icon: email,
        path: "/campaign",
    },
    ]